import React from 'react';
import { Link } from 'react-router-dom';
import FAQAccordion from '../components/FAQAccordion';
import { Helmet } from 'react-helmet';

const FAQPage = () => {
  const faqData = [
    { question: 'Как оформить заказ?', answer: 'Для оформления заказа выберите товар и нажмите "Оформить заказ". Выберите удобный для Вас способ связи с менеджером студии' },
    { question: 'Какие есть способы доставки?', answer: 'Доступна доставка курьером и самовывоз из магазина. На территории ТЦ есть бесплатная парковка в течение 2-х часов' },
    { question: 'Как оплатить заказ?', answer: 'Оплата доступна банковской картой, наличными и переводом по QR-коду' },
    { question: 'Как сохранить свежесть цветов?', 
      answer: <>Чтобы цветы сохранили свежесть как можно дольше воспользуйтесь нашей <Link to="/freshnote"><strong>памяткой</strong></Link></> },
    { question: 'Как получить скидку на заказ?', answer: 'При заказе через сайт скидка на первый заказ 10%! Также скидка 5% на КАЖДЫЙ заказ постоянным клиентам!' },
    { question: 'Как заказать букет в нерабочее время студии?', answer: 'Принимаем заказы ежедневно с 8:00 до 22:00 в обычные дни и круглосуточно в предпраздничные/праздничные дни. Если Вам срочно нужен букет постараемся ответить в любое время суток!' },
    { question: 'Если получателя нет на месте?', answer: 'Заказ может быть оставлен консьержам/охране и т.д. по предварительному согласованию с Вами' },
    { question: 'Как оформить предзаказ?', answer: 'Предзаказ можно оформить связавшись с менеджером студии через кнопку "Оформить заказ" или по контактам в "Подвале" сайта' },
    { question: 'У меня трудности с выбором букета/композиции', answer: 'Свяжитесь с нами и мы поможем выбрать Вам оптимальный подарок для Вашего повода или мероприятия!' },
  ];

  return (
    <section className="faq-page container mt-2 mb-4">
      <Helmet>
        <title>Ответы на частые вопросы</title>
        <meta name="description" content="Ответы по доставке цветов, покупке букетов, времени доставки цветов. Как получить скидку на заказ цветов" />
      </Helmet>
      <h1 className='text-center'>Часто задаваемые вопросы</h1>
      {faqData.map((faq, index) => (
        <FAQAccordion key={index} question={faq.question} answer={faq.answer} />
      ))}
    </section>
  );
};

export default FAQPage;