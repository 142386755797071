import React, { useEffect } from 'react';
import telegram_icon from '../assets/images/img/sm_icon/telegram.svg';
import whatsapp_icon from '../assets/images/img/sm_icon/whatsapp.svg';
import phone_icon from '../assets/images/img/sm_icon/phone.svg';

function ButtonModal() {
    // Используем хук useEffect для работы с DOM-элементами после рендера компонента
    useEffect(() => {
      const modal = document.getElementById('modal');
      const orderBtn = document.getElementById('order-btn');
      const closeBtn = document.querySelector('.close');
  
      // Открытие модального окна
      orderBtn.addEventListener('click', () => {
        modal.style.display = 'block';
      });
  
      // Закрытие модального окна при нажатии на крестик
      closeBtn.addEventListener('click', () => {
        modal.style.display = 'none';
      });
  
      // Закрытие модального окна при клике вне окна
      window.addEventListener('click', (event) => {
        if (event.target === modal) {
          modal.style.display = 'none';
        }
      });
  
      // Очистка событий при размонтировании компонента
      return () => {
        orderBtn.removeEventListener('click', () => {
          modal.style.display = 'block';
        });
        closeBtn.removeEventListener('click', () => {
          modal.style.display = 'none';
        });
        window.removeEventListener('click', (event) => {
          if (event.target === modal) {
            modal.style.display = 'none';
          }
        });
      };
    }, []); // [] означает, что useEffect сработает один раз при монтировании компонента
  
    return (
        <div>
        <button id="order-btn" className="btn btn-warning btn-lg text-white btn-outline-dark order-btn">Оформить заказ</button>
        <div id="modal" className="modal">
          <div className="modal-content">
            <span className="close">&times;</span>
            <h5 className='text-light'>Выберите способ связи:</h5>
            <div className="contact-options">
              <a href="https://t.me/m/HjXn627wZGVi" target="_blank" className="contact-link"><p>Telegram</p><img src={telegram_icon} alt="telegram logo" /></a>
              <a href="https://wa.me/message/MUWXIHXASF6EA1" target="_blank" className="contact-link"><p>Whatsapp</p><img src={whatsapp_icon} alt="WhatsApp" /></a>
              <a href="tel:+79932800156" className="contact-link"><p>Позвонить</p><img src={phone_icon} alt="Call" /></a>
            </div>
          </div>
        </div>
        </div>
    );
}
  
  export default ButtonModal;