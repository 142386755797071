import React from 'react';

function Description() {
  return (
    <section className="description py-3 bg-black" fetchpriority="high">
      <div className="container text-center">
        <p className="lead mb-0">Предлагаем свежесрезанные цветы из Эквадора, Голландии и от местных производителей. Флористы нашей студии помогут подобрать подарок для любого случая. Наша цель – сделать каждый момент Вашей встречи с близкими незабываемым и наполненным яркими эмоциями!</p>
      </div>
    </section>
  );
}

export default Description;
