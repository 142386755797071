import './styles.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton';
import SnowAnimation from './components/SnowAnimation';

import NotFound from './components/NotFound';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Description from './components/Description';
import Categories from './components/Categories';
import Discount from './components/Discount';
import Footer from './components/Footer';
import ProductPage from './components/ProductPage';

// Pages
import DiscountPage from './pages/DiscountPage';
import Bouquet from './pages/Bouquets';
import MonoBouquets from './pages/MonoBouquets';
import Compositions from './pages/Compositions';
import Toys from './pages/Toys';
import Balls from './pages/Balls';
import ArtificialFlowers from './pages/ArtificialFlowers';
import AboutUs from './pages/AboutUs'; 
import Delivery from './pages/Delivery';
import FreshNote from './pages/FreshNote';
import FAQPage from './pages/FAQPage';

function App() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('/data/products.json')
      .then((response) => response.json())
      .then((data) => {
        const productsWithIds = data.map((product) => {
          return {
            ...product,
            id: product.id || uuidv4(),
          };
        });
        setProducts(productsWithIds);
      })
      .catch((error) => console.error('Ошибка при загрузке товаров:', error));
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ScrollToTopButton />
      <SnowAnimation />
      <Navbar />

      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <Features />
            <Description />
            <Categories />
            <Discount />
          </>
        } />
        <Route path="/discountpage" element={<DiscountPage products={products
          .filter(p => p.discountPrice != null && p.available === 1) 
          .sort((a, b) => b.discountPrice - a.discountPrice)} />} />
        <Route path="/bouquets" element={<Bouquet products={products.filter(p => p.category === 'bouquets')} />} />
        <Route path="/monobouquets" element={<MonoBouquets products={products.filter(p => p.category === 'monobouquets')} />} />
        <Route path="/compositions" element={<Compositions products={products.filter(p => p.category === 'compositions')} />} />
        <Route path="/toys" element={<Toys products={products.filter(p => p.category === 'toys')} />} />
        <Route path="/balls" element={<Balls products={products.filter(p => p.category === 'balls')} />} />
        <Route path="/artificialflowers" element={<ArtificialFlowers products={products.filter(p => p.category === 'artificialflowers')} />} />  
        <Route path="/product/:id" element={<ProductPage products={products} />} />
        <Route path="/aboutus" element={<AboutUs key={Date.now()} />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path ="/freshnote" element={<FreshNote />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;