import React from 'react';

function Discount() {

  return (
    <section className="discount text-center text-white">
      <p className="mb-4 discount-text">Сделайте заказ <span style={{ textDecoration: 'underline' }}>сейчас</span> со скидкой 10%</p>
    </section>
  );
}

export default Discount;