function MargueeWrapper () {

    return (      
    
        <div class="marquee-wrapper mb-4">
            <div class="marquee-items-wrapper">
            <div class="marquee-items">
                <div class="marquee-item">🚚 Бесплатная доставка от 3000₽</div>
                <div class="marquee-item">🚀 Доставка от 30 мин.</div>
                <div class="marquee-item">📝 Открытка в подарок</div>
                <div class="marquee-item">📸 Фото букета перед отправкой</div>
                <div class="marquee-item">⏳ Средство для ухода за букетом</div>
                <div class="marquee-item">📖 Памятка свежести</div>
            </div>
            <div aria-hidden="true" class="marquee-items">
                <div class="marquee-item">🚚 Бесплатная доставка от 3000₽</div>
                <div class="marquee-item">🚀 Доставка от 30 мин.</div>
                <div class="marquee-item">📝 Открытка в подарок</div>
                <div class="marquee-item">📸 Фото букета перед отправкой</div>
                <div class="marquee-item">⏳ Средство для ухода за букетом</div>
                <div class="marquee-item">📖 Памятка свежести</div>
            </div>
            </div>
        </div>
      
    )
}

export default MargueeWrapper;