import Discount from "../components/Discount";
import { Helmet } from "react-helmet";

const FreshNote = () => {

    return (
        <section class="care-guide container">
            <Helmet>
                <title>Как ухаживать за цветами и букетами</title>
                <meta name="description" content="Памятка свежести цветов. Как ухаживать за букетом после получения." />
            </Helmet>            
            <h1 className="text-center">Памятка свежести</h1>
            <p>Получить букет цветов — это радость и напоминание о том, кто его подарил. Чтобы цветы радовали Вас дольше, следуйте нескольким простым советам.</p>
            
            <h2>Советы по уходу за букетом</h2>
            <ol>
                <li>
                    <strong>Ставьте букет в воду сразу после получения.</strong>
                    <p>Если на улице холодно, оставьте цветы согреться при комнатной температуре на 15-20 минут. Не забывайте, что без воды цветы быстро теряют свежесть. Не оставляйте букет в машине или на холоде более 30 минут.</p>
                </li>
                <li>
                    <strong>Выбирайте подходящую вазу.</strong>
                    <p>Ваза должна быть достаточно вместительной, чтобы цветы не падали и стебли не были повреждены.</p>
                </li>
                <li>
                    <strong>Используйте чистую воду.</strong>
                    <p>Наливайте в вазу обычную прохладную воду из под крана. Важно: вода должна быть свежей и без примесей.</p>
                </li>
                <li>
                    <strong>Подрезайте стебли перед тем, как поставить цветы в вазу.</strong>
                    <p>Делайте срез под углом 45°. Это помогает избежать закупоривания капилляров, по которым вода поступает к бутону.</p>
                </li>
                <li>
                    <strong>Избегайте жары и сквозняков.</strong>
                    <p>Цветы лучше всего держать в прохладных условиях, подальше от батарей, солнечных лучей и табачного дыма.</p>
                </li>
                <li>
                    <strong>Меняйте воду ежедневно и подрезайте стебли.</strong>
                    <p>Каждый день меняйте воду и подрезайте стебли на 1-2 см. Убедитесь, что листья не касаются воды — это предотвращает образование бактерий.</p>
                </li>
            </ol>
            <Discount />
        </section>
    );
};

export default FreshNote;