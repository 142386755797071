import React from 'react';

function Features() {
  return (
    <section className="features py-3 text-white">
      <div className="container">
        <div className="row text-center advantage">
          <div className="col-lg-3 col-md-6 mb-3 rounded advantage">
            <div className="p-3 bg-black rounded advantage-text">Только свежие цветы</div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 rounded advantage">
            <div className="p-3 bg-black rounded advantage-text">Быстрая доставка</div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 rounded advantage">
            <div className="p-3 bg-black rounded advantage-text">Доступные цены</div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 rounded advantage">
            <div className="p-3 bg-black rounded advantage-text">PRO - подход</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
