import React from 'react';
import ProductCard from '../components/ProductCard';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MonoBouquets = ({ products }) => {

  const sortedProducts = [...products].sort((a, b) => b.available - a.available);

  return (
    <div className="bouquet-page mt-4">
      <Helmet>
        <title>Монобукеты к любому поводу в Королеве</title>
        <meta name="description" content="Монобукеты с доставкой по Королеву по доступным ценам. Доставка цветов. Монобукеты по сниженной цене. Фото перед доставкой. Открытка в подарок." />
      </Helmet>
      <div className="product-list container d-flex mb-3">
        {sortedProducts.map((product) => (
          <Link to={`/product/${product.id}`} key={product.id}>
            <ProductCard
              image={product.image}
              title={product.title}
              available={product.available}
              discountPrice={product.discountPrice}
              price={product.price}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MonoBouquets;