import React from 'react';
import { Link } from 'react-router-dom';
import discountCat from '../assets/images/img/category/discount_cat.webp'
import bouquets from '../assets/images/img/category/bouquets.webp'
import monobouquets from '../assets/images/img/category/monobouquets.webp';
import compositions from '../assets/images/img/category/compositions.webp'
import toys from '../assets/images/img/category/toys.webp'
import balls from '../assets/images/img/category/balls.webp'
import artificialFlowers from '../assets/images/img/category/artificial_flowers.webp'

function Categories() {
  return (
    <section className="categories pt-5">
      <div className="container">
        <div className="row text-center gap-3">
        <Link to="/discountpage">
            <div className="category mb-4 bg-black">
              <h3 className="mt-3">Специальные предложения</h3>
              <img src={discountCat} alt='#'></img>
            </div>
          </Link>
          <Link to="/bouquets">
            <div className="category mb-4 bg-black">
              <h3 className="mt-3">Букеты</h3>
              <img src={bouquets} alt='#'></img>
            </div>
          </Link>
          <Link to="/monobouquets">
            <div className="category mb-4 bg-black">
              <h3 className="mt-3">Монобукеты</h3>
              <img src={monobouquets} alt='#'></img>
            </div>
          </Link>
          {/* <Link to="/compositions">
            <div className="category mb-4 bg-black">
              <h3 className="mt-3">Композиции</h3>
              <img src={compositions} alt='#'></img>
            </div>
          </Link> */}
          <Link to="/toys">
            <div className="category col-md-4 mb-4 bg-black">
              <h3 className="mt-3">Игрушки</h3>
              <img src={toys} alt='#'></img>
            </div>
          </Link>
          <Link to="/balls">
            <div className="category col-md-4 mb-4 bg-black">
              <h3 className="mt-3">Гелиевые шары</h3>
              <img src={balls} alt='#'></img>
            </div>
          </Link>
          {/* <Link to="/artificialflowers">
            <div className="category col-md-12 mb-4 bg-black">
              <h3 className="mt-3">Искусственные цветы</h3>
              <img src={artificialFlowers} alt='#'></img>
            </div>
          </Link> */}
        </div>
      </div>
    </section>
  );
}

export default Categories;
