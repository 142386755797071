import React from 'react';

const ProductCard = ({ image, title, available, discountPrice, price, onCardClick }) => {

  const truncateTitle = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...'; // Обрезаем строку и добавляем троеточие
    }
    return text;
  };

  return (
    <div className="product-card container d-flex flex-wrap" onClick={onCardClick}>
      <img src={image} alt={title} className="product-image" loading="lazy" />
      <div className="product-details">
        <h3>{truncateTitle(title, 25)}</h3>
        <div className='d-flex justify-content-end'>

          {available === 1 ? (
            <>
              {discountPrice ? (
                <div className="product-card-bg-price-discount">
                  <p className="product-card-price-discount">{discountPrice} ₽</p>
                </div>
              ) : null}
              
              <div className={`product-card-bg-price ${discountPrice ? 'price-crossed' : ''}`}>
                <p className="product-card-price">{price} ₽</p>
              </div>
            </>
          ) : (
            <div className="product-unavailable">
              <div className='unavailable-bg d-flex'>
               <p className="unavailable-text">Будет позже 😢</p>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default ProductCard;