import React from 'react';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Zoom, Pagination } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import ButtonModal from './ButtonModal';

const ProductPage = ({ products }) => {
  const { id } = useParams();
  const product = products.find((p) => p.id === Number(id));

  if (!product) {
    return <h3 className='text-center'>Товар не найден</h3>;
  }

  return (
    <div>
      <Helmet>
        <title>{product.title} в Королёве</title>
        <meta name="description" content={product.modalData.description ? product.modalData.description.slice(0, 160) + "..." : "Студия Цветов Floristel в Королеве"} />
      </Helmet>
      <div className='container d-flex'>
        <div className="product-page">
          <div className='product-page-inner d-flex flex-wrap py-3 mb-3'>
            <Swiper
              modules={[Pagination, Zoom]}
              zoom={{ maxRatio: 2 }}
              spaceBetween={10}
              slidesPerView={1}
              grabCursor={true}
              pagination={{ clickable: true }}
              className="swiper-container"
            >
              <SwiperSlide key="main-image">
                <div className="swiper-zoom-container">
                  <img src={product.image} alt={product.title} className="product-image-page" />
                </div>
              </SwiperSlide>

              {product.modalData.additionalImages &&
                product.modalData.additionalImages.length > 0 &&
                product.modalData.additionalImages.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-zoom-container">
                      <img src={image} alt={`Изображение ${index + 1}`} className="product-image-page" />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            
            <div className='product-page-text-inner'>
              <h1 className='lh-1 fs-2'>{product.title}</h1>
              <p
                  className="lh-sm"
                  dangerouslySetInnerHTML={{ __html: product.modalData.description.replace(/\n/g, '<br />') }}
                ></p>
              <p className='lh-sm'>{product.modalData.characteristics.compound}</p>
              <ul>
                <li>Ширина: {product.modalData.characteristics.width}</li>
                <li>Высота: {product.modalData.characteristics.height}</li>
                <li>Цвет: {product.modalData.characteristics.color}</li>
              </ul>

              <div className='d-flex'>
                {product.available === 1 ? (
                  <>
                    {product.discountPrice ? (
                      <div className="product-card-bg-price-discount">
                        <p className="product-card-price-discount">{product.discountPrice} ₽</p>
                      </div>
                    ) : null}
                    <div className={`product-card-bg-price ${product.discountPrice ? 'price-crossed' : ''}`}>
                      <p className="product-card-price">{product.price} ₽</p>
                    </div>
                  </>
                ) : (
                  <div className="product-unavailable">
                    <div className='unavailable-bg unavailable-bg-page d-flex'>
                      <p className="unavailable-text fs-6">Будет позже 😢</p>
                    </div>
                  </div>
                )}
              </div>

              {product.available === 1 && <ButtonModal />}
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default ProductPage;
