import React from "react";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Autoplay, Zoom, Pagination } from 'swiper/modules';

import signbouquet from '../assets/images/img/about_us/sign_bouquet.webp';
import signboard from '../assets/images/img/about_us/signboard.webp';
import ballons from '../assets/images/img/about_us/ballons.webp';
import toys from '../assets/images/img/about_us/toys.webp';
import boxes from '../assets/images/img/about_us/boxes.webp';
import shop from '../assets/images/img/about_us/shop.webp';
import Discount from "../components/Discount";


const AboutUs = () => {
    return (
        <div className="container">
            <Helmet>
            <title>О студии цветов в Королёве</title>
            <meta name="description" content="Продаем только свежие цветы из Эквадора, Голландии и от местных производителей. Находимся в самом центре Королёва, рядом с ж/д станцией Болшево. Благодаря этому Ваши заказы доставляются быстро, а цена доставки остается минимальной, что делает приятные сюрпризы еще доступнее." />
            </Helmet>
            <div className="main aboutus mt-4 mb-5">
                <h1 className="text-center text-white">Студия Цветов Floristel в Королёве</h1>
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-4">
                    <div className="">
                        <Swiper
                        modules={[Autoplay, Pagination, Zoom]}
                        zoom={{ maxRatio: 2 }}
                        spaceBetween={10}
                        speed={2500}
                        loopPreventsSlide={false}
                        loop={true}
                        autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        className="swiper-container swiper-container-aboutus"
                        >
                        <SwiperSlide key="slide1">
                            <div className="swiper-zoom-container">
                                <img src={signbouquet} alt="Фото букета у неоновой вывески" className="aboutus-image rounded" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="slide2">
                            <div className="swiper-zoom-container">
                                <img src={signboard} alt="Неоновая вывеска Подари ей цветы" className="aboutus-image rounded" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="slide3">
                            <div className="swiper-zoom-container">
                                <img src={ballons} alt="Балон для гелиевых шаров" className="aboutus-image rounded" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="slide4">
                            <div className="swiper-zoom-container">
                                <img src={toys} alt="Мягкие игрушки" className="aboutus-image rounded" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="slide5">
                            <div className="swiper-zoom-container">
                                <img src={boxes} alt="Подарочные коробки" className="aboutus-image rounded" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="slide6">
                            <div className="swiper-zoom-container">
                                <img src={shop} alt="Фото студии цветов" className="aboutus-image rounded" />
                            </div>
                        </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="text-white aboutus-text">
                        <p>Продаем только свежие цветы из Эквадора, Голландии и от местных производителей.</p>
                        <p>Находимся в самом центре Королёва, рядом с ж/д станцией Болшево. Благодаря этому Ваши заказы доставляются быстро, а цена доставки остается минимальной, что делает приятные сюрпризы еще доступнее.</p>
                        <p>Собираем композиции и букеты ежедневно с 8:00 до 22:00, чтобы Вы могли порадовать близких с раннего утра до позднего вечера. В праздничные дни работаем еще раньше, а заказы принимаем круглосуточно!</p>
                        <p>Разнообразный ассортимент, способный удовлетворить любые предпочтения: от монобукетов и изысканных цветочных композиций до мягких игрушек и воздушных шаров создающих праздничную атмосферу.</p>
                        <p>Цены в нашей студии — это еще один приятный момент. Мы стремимся сделать красоту доступной, поэтому у нас Вы найдете букеты и композиции для любого бюджета, не жертвуя качеством и свежестью цветов.</p>
                    </div>
                </div>
                <Discount />
            </div>
        </div>
    );
};

export default AboutUs;