const SnowAnimation = () => {
    return(
        <div class="snowflakes" aria-hidden="true">
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
            <div class="snowflake">
            ❄️
            </div>
        </div>
    );
};

export default SnowAnimation;