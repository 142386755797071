import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/img/logo-nav.webp';

function Navbar() {
  const burgerRef = useRef(null);
  const headerRef = useRef(null);
  const navLinksRef = useRef(null);

  const location = useLocation(); // Отслеживание изменения маршрута

  useEffect(() => {
    const burger = burgerRef.current;
    const navLinks = navLinksRef.current;

    // Обработчик для клика на бургер-меню
    const handleBurgerClick = () => {
      burger.classList.toggle('active');
      navLinks.classList.toggle('active');
    };

    // Добавляем обработчики событий для открытия меню
    burger.addEventListener('click', handleBurgerClick);

    // Закрываем меню при переходе на другой маршрут
    navLinks.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', () => {
        burger.classList.remove('active');
        navLinks.classList.remove('active');
      });
    });

    // Очистка обработчиков при размонтировании компонента
    return () => {
      burger.removeEventListener('click', handleBurgerClick);
      navLinks.querySelectorAll('a').forEach(link => {
        link.removeEventListener('click', () => {
          burger.classList.remove('active');
          navLinks.classList.remove('active');
        });
      });
    };
  }, [location]); // Обновляем эффект при изменении маршрута

  //Выпадающий список 
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Открываем меню при наведении мыши
  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  // Закрываем меню при уходе мыши
  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLinkClick = () => {
    setDropdownOpen(false); // Закрываем меню при клике на ссылку
  };

  return (
    <header className="py-3 header" id="header" ref={headerRef}>
      <div className="burger" id="burger" ref={burgerRef}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className="container d-flex justify-content-center" id="nav">
        <ul className="nav align-items-center" id="nav-links" ref={navLinksRef}>
          <li className="nav-item">
            <Link to="/" href="index.html" onClick={handleLinkClick}><img className="logo-nav" src={logo} alt="logo-nav" />
            </Link>
          </li>
          <li>
            <Link to="discountpage" className="nav-link text-white">Акции</Link>
          </li>
          <li className="nav-item dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span className="nav-link text-white dropdown-toggle" onClick={handleDropdownToggle}>
              Цветы
            </span>
            <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
              <li>
                <Link to="/bouquets" className="dropdown-item" onClick={handleLinkClick}>Букеты</Link>
              </li>
              <li>
                <Link to="/monobouquets" className="dropdown-item" onClick={handleLinkClick}>Монобукеты</Link>
              </li>
              {/* <li>
                <Link to="/compositions" className="dropdown-item" onClick={handleLinkClick}>Композиции</Link>
              </li> */}
            </ul>
          </li>
          <li className="nav-item">
              <Link to="/toys" className="nav-link text-white">Игрушки</Link>
          </li>
          <li className="nav-item">
            <Link to="/balls" className="nav-link text-white">Гелиевые шары</Link>
          </li>
          <li className="nav-item">
            <Link to="/aboutus" className="nav-link text-white">О нас</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
